
import { UnitPlanAPI } from '@/api/academic-definitions/unit-plan.api'
import { ClassPlan } from '@/models/academic-definitions/interfaces/composition/Syllabus'
import { UnitPlan } from '@/models/academic-definitions/interfaces/UnitPlan'
import Checkbox from 'primevue/checkbox'
import { defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  props: {
    unit: {
      type: Object as PropType<UnitPlan>,
      required: true
    },
    classesPlan: {
      type: Object as PropType<ClassPlan[]>,
      required: true
    },
    editingUnitId: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  components: { Checkbox },

  data () {
    const selectedClasses = this.classesPlan.filter(cp => this.unit.classPlanIds?.includes(cp._id))
    selectedClasses.forEach(cp => {
      cp.isSelectedBy = this.unit.id
    })

    const unitData = { ...this.unit, unitName: this.unit.name }

    return { selectedClasses, unitData }
  },

  methods: {
    edit () {
      this.$emit('edit', this.unit.id)
    },
    refreshIsSelacted () {
      this.classesPlan.forEach(cp => {
        if (cp.isSelectedBy === this.unit.id) {
          cp.isSelectedBy = undefined
        }
      })
      this.selectedClasses.forEach(cp => {
        cp.isSelectedBy = this.unit.id
      })
    },
    async save () {
      this.unitData.classPlanIds = this.selectedClasses?.map(sc => sc._id)
      this.refreshIsSelacted()
      this.loading = true
      let unitSaved
      if (this.unitData.id === 'new') {
        unitSaved = await UnitPlanAPI.create({ ...this.unitData, order: this.index + 1, id: undefined, name: this.unitData.unitName })
        this.unitData.id = unitSaved.id
      } else {
        unitSaved = await UnitPlanAPI.update({ ...this.unitData, order: this.index + 1, name: this.unitData.unitName })
      }
      this.loading = false
      this.$emit('editSelectedClass', unitSaved)
    },
    isDisabled (clazz: ClassPlan) : boolean {
      return !!clazz.isSelectedBy && clazz.isSelectedBy !== this.unitData.id
    },
    async deleteUnit () {
      this.unitData.classPlanIds = this.selectedClasses?.map(sc => sc._id)
      this.refreshIsSelacted()
      this.loading = true
      if (this.unitData.id && this.unitData.id !== 'new') {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await UnitPlanAPI.deleteBySyllabus(this.unitData.id, this.unitData.syllabusId!)
      }
      this.loading = false
      this.$emit('deleteSelectedClass', this.unitData.id)
    }
  },

  setup () {
    const loading = ref<boolean>()

    return { loading }
  }
})
