
import { useEducationPlanStore } from '@/store/modules/academic-definitions/educationPlan-store'
import { computed, defineComponent, PropType, ref } from 'vue'

import CoursePlanSelector from '@/components/educationPlans/selectors/CoursePlanSelector.vue'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
export default defineComponent({
  components: {
    CoursePlanSelector
  },
  props: {
    coursePlans: {
      type: Array as PropType<CoursePlan[]>,
      default: () => []
    },
    preKnowledge: String,
    requeriments: String,
    postSkills: Array as PropType<string[]>
  },
  emits: [
    'update:preKnowledge',
    'update:postSkills',
    'update:requeriments'
  ],
  setup (props) {
    const postSkillsRef = computed(() => props.postSkills)
    const educationPlanStore = useEducationPlanStore()
    const loading = useEducationPlanStore().isLoading
    const educationPlan = computed(() => educationPlanStore.educationPlan)
    const allCoursePlans = computed(() => educationPlanStore.allCoursePlans)
    const postSkillInput = ref<string>()
    const createPostSkills = () => {
      if (postSkillInput.value !== undefined) {
        postSkillsRef.value?.push(postSkillInput.value)
        return 0
      }
      return 0
    }
    const removeElement = (i: number) => {
      postSkillsRef.value?.splice(i, 1)
      return 0
    }
    return {
      educationPlan,
      loading,
      allCoursePlans,
      postSkillInput,
      createPostSkills,
      removeElement,
      postSkillsRef
    }
  }
})
