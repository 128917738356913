import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    panelClass: "overlay-gray",
    options: _ctx.types,
    placeholder: "Seleccionar dificultad",
    optionLabel: "name",
    optionValue: "value"
  }, null, 8, ["options"]))
}