import { CareerPlanAPI } from '@/api/academic-definitions/career-plan-api'
import { CoursePlanAPI } from '@/api/academic-definitions/course-plan.api'
import { CareerPlan } from '@/models/academic-definitions/classes/CareerPlan'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { EducationPlan } from '@/models/academic-definitions/classes/EducationPlan'
import { EducationPlanType } from '@/models/academic-definitions/enums'
import { defineStore } from 'pinia' //

interface EducationPlanState {
  isLoading: boolean
  educationPlan: EducationPlan | CareerPlan | null
  error: unknown
  allCoursePlans: CoursePlan[] | null
  allCareersWithCoursePlan: CareerPlan[] | null
}

export const useEducationPlanStore = defineStore({
  id: 'educationPlan-store',
  state: (): EducationPlanState => ({
    isLoading: false,
    educationPlan: null,
    error: null,
    allCoursePlans: [],
    allCareersWithCoursePlan: []
  }),
  actions: {
    async loadData (planId: string, type: EducationPlanType) {
      try {
        this.isLoading = true
        const educationPlan = type === EducationPlanType.CoursePlan
          ? await CoursePlanAPI.findById(planId)
          : await CareerPlanAPI.findById(planId)
        this.educationPlan = educationPlan
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
      }
    },
    async loadAllCoursePlans () {
      try {
        this.isLoading = true
        const allCoursePlans = await CoursePlanAPI.findAll()
        this.allCoursePlans = allCoursePlans
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
      }
    },
    async loadCareersWithCoursePlan (id: string) {
      try {
        this.isLoading = true
        const careerPlans = await CareerPlanAPI.findCareersByCoursePlanId(id)
        this.allCareersWithCoursePlan = careerPlans
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
      }
    }
  }
})
