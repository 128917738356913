
import { computed, defineComponent, PropType } from 'vue'
import { EducationPlanConfiguration, EducationPlanWWWPaths } from '@/models/academic-definitions/enums'
import { isCareerPlan } from '@/models/academic-definitions/interfaces/CareerPlan'
import CoursesMultipleSelector from '@/components/commons/selectors/CoursesMultipleSelector.vue'
import DifficultySelector from '@/components/educationPlans/selectors/DifficultySelector.vue'
import { useRoute } from 'vue-router'
import { EducationRouteNames } from '@/router/route-names'
import { CoursePlan, isCoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { CareerPlan } from '@/models/academic-definitions/classes/CareerPlan'
import { EducationPlan } from '@/models/academic-definitions/classes/EducationPlan'
import { toggleElementsFromArray } from '@/utils/helpers'
export default defineComponent({
  components: {
    DifficultySelector,
    CoursesMultipleSelector
  },
  props: {
    educationPlan: Object as PropType<EducationPlan>,
    coursePlans: {
      type: Array as PropType<CoursePlan[]>,
      default: () => []
    },
    careerPlans: {
      type: Array as PropType<CareerPlan[]>,
      default: () => []
    },
    name: String,
    path: String,
    shortDescription: String,
    longDescription: String,
    careerCourses: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    config: {
      type: Array as PropType<EducationPlanConfiguration[]>,
      default: () => []
    },
    difficulty: String,
    laptopRequired: Boolean,
    preSale: Boolean,
    published: Boolean,
    display: Boolean
  },
  emits: [
    'update:name',
    'update:path',
    'update:shortDescription',
    'update:laptopRequired',
    'update:preSale',
    'update:published',
    'update:display',
    'update:config',
    'update:careerCourses'
  ],
  setup (props) {
    const educationPlan = computed(() => props.educationPlan)
    const { name: routeName } = useRoute()
    const educationPlanPath = computed<EducationPlanWWWPaths>(() => {
      return isCareerPlan(educationPlan.value)
        ? EducationPlanWWWPaths.CareerPlan
        : EducationPlanWWWPaths.CoursePlan
    })
    return {
      educationPlanPath,
      isCareerPlan,
      routeName,
      EducationRouteNames,
      EducationPlanConfiguration,
      isCoursePlan,
      toggleElementsFromArray
    }
  }
})
