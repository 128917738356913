
import { UnitPlanAPI } from '@/api/academic-definitions/unit-plan.api'
import { SyllabusAPI } from '@/api/academic-definitions/syllabus.api'

import { defineComponent, onMounted, ref } from 'vue'

import SyllabusSelector from '@/components/educationPlans/selectors/SyllabusSelector.vue'
import Unit from '../../../components/Unit.vue'
import { ClassPlan, Syllabus } from '@/models/academic-definitions/interfaces/composition/Syllabus'
import { UnitPlan } from '@/models/academic-definitions/interfaces/UnitPlan'
import { ClassPlanAPI } from '@/api/academic-definitions/class-plan.api'

export default defineComponent({
  components: {
    SyllabusSelector,
    Unit
  },
  props: {
    coursePlanId: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const syllabumActive = ref<Syllabus[]>([])
    const unitPlans = ref<UnitPlan[]>([])
    const classesPlan = ref<ClassPlan[]>([])
    const editingUnitId = ref<string>()
    const loading = ref<boolean>()
    const programa = 'programa.pdf'
    onMounted(async () => {
      try {
        loading.value = true
        syllabumActive.value = await SyllabusAPI.findLastVersions(
          props.coursePlanId
        )
        if (syllabumActive.value.length > 0) {
          classesPlan.value = await ClassPlanAPI.findAll(syllabumActive.value[0].id)
          unitPlans.value = await UnitPlanAPI.findBySillabusId(syllabumActive.value[0].id)
        }
      } catch (e) {
        console.error(e)
      } finally {
        loading.value = false
      }
    })
    const changePdfPreview = () => {
      console.log('Cambiado!')
      return 0
    }

    const addUnit = () => {
      const tempId = 'new'
      unitPlans.value?.push({ id: tempId, syllabusId: syllabumActive.value[0].id })
      editingUnitId.value = tempId
    }

    const onEdit = (id: string) => {
      editingUnitId.value = id
    }

    const editSelectedClass = async (unit: UnitPlan) => {
      editingUnitId.value = undefined
      unitPlans.value = await UnitPlanAPI.findBySillabusId(syllabumActive.value[0].id)
    }

    const deleteSelectedClass = async (unit: UnitPlan) => {
      unitPlans.value = await UnitPlanAPI.findBySillabusId(syllabumActive.value[0].id)
      editingUnitId.value = undefined
    }

    return {
      syllabumActive,
      loading,
      changePdfPreview,
      unitPlans,
      addUnit,
      classesPlan,
      editingUnitId,
      onEdit,
      editSelectedClass,
      deleteSelectedClass,
      programa
    }
  }
})
