import { UnitPlan } from '@/models/academic-definitions/interfaces/UnitPlan'
import { Api } from '../api'
import { CRUD } from '../crud'

class UnitPlanApi implements CRUD<UnitPlan> {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  public async findBySillabusId(id: string): Promise<UnitPlan[]> {
    return await Api.get(
      `${this.baseURL}/v1/academic-definitions/coursePlan/syllabus/${id}/unitPlan`
    )
  }

  public async create(syllabusDTO: Partial<UnitPlan>): Promise<UnitPlan> {
    const { syllabusId, ...dto } = syllabusDTO
    return await Api.post(
      `${this.baseURL}/v1/academic-definitions/coursePlan/syllabus/${syllabusId}/unitPlan`,
      dto
    )
  }

  public async deleteBySyllabus(
    id: string,
    syllabusId: string
  ): Promise<UnitPlan> {
    return await Api.del(
      `${this.baseURL}/v1/academic-definitions/coursePlan/syllabus/${syllabusId}/unitPlan/${id}`
    )
  }

  public async update(syllabusDTO: Partial<UnitPlan>): Promise<UnitPlan> {
    const { syllabusId, id, ...dto } = syllabusDTO
    return await Api.patch(
      `${this.baseURL}/v1/academic-definitions/coursePlan/syllabus/${syllabusId}/unitPlan/${id}`,
      dto
    )
  }
}

export const UnitPlanAPI = new UnitPlanApi()
