import { ICoursePlan } from './CoursePlan'
import { IEducationPlan } from './EducationPlan'
export interface ICareerPlan extends IEducationPlan {
  courses: ICoursePlan[] | string[]
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function isCareerPlan (object: Record<string, unknown> | EducationPlan | undefined): object is ICareerPlan {
  return object ? 'courses' in object : false
}
