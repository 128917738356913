
import { CareerPlanAPI } from '@/api/academic-definitions/career-plan-api'
import { CoursePlanAPI } from '@/api/academic-definitions/course-plan.api'
import { useForm } from '@/hooks/useForm'
import { CoursePlan, isCoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { EducationPlan } from '@/models/academic-definitions/classes/EducationPlan'
import { isCareerPlan } from '@/models/academic-definitions/interfaces/CareerPlan'
import { EducationRouteNames } from '@/router/route-names'
import { defineComponent, onMounted, PropType, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useToast } from 'primevue/usetoast'

import AboutPlanTab from './GeneralInfoTabs/AboutPlan.vue'
import AboutPreviousToPlanTab from './GeneralInfoTabs/AboutPreviousToPlan.vue'
import UnitSyllabus from './GeneralInfoTabs/UnitSyllabus.vue'

export default defineComponent({
  components: {
    AboutPlanTab,
    AboutPreviousToPlanTab,
    UnitSyllabus
  },
  props: {
    educationPlan: {
      type: Object as PropType<EducationPlan>
    },
    coursePlans: {
      type: Array
    },
    careerPlans: {
      type: Array
    }
  },
  setup (props) {
    const toast = useToast()
    const educationPlan = ref(props.educationPlan)
    const saving = ref(false)
    const publishing = ref(false)
    const msgSuccess = ref('')
    const { name: routeName } = useRoute()
    const { formValues, patchValues } = useForm<any>({
      information: {
        name: '',
        shortDescription: '',
        longDescription: '',
        preKnowledge: '',
        dedication: {
          difficulty: null,
          text: ''
        },
        postSkills: [],
        requeriments: ''
      },
      config: [],
      courses: [],
      links: {
        path: ''
      },
      published: false,
      display: true
    })
    async function saveInfo (): Promise<void> {
      if (educationPlan.value) {
        try {
          isCoursePlan(educationPlan.value) && await CoursePlanAPI.update(formValues, educationPlan.value?.id)
          isCareerPlan(educationPlan.value) && await CareerPlanAPI.update(formValues, educationPlan.value?.id)
          toast.add({ severity: 'success', detail: msgSuccess.value, life: 3500 })
        } catch (error) {
          console.log(error)
        } finally {
          saving.value = false
          publishing.value = false
        }
      } else {
        try {
          if (routeName === EducationRouteNames.CONTENT_COURSES_CREATE_ABOUT) {
            const courseCreated = await CoursePlanAPI.create(formValues)
            educationPlan.value = courseCreated as EducationPlan
          }
          if (routeName === EducationRouteNames.CONTENT_CAREERS_CREATE_ABOUT) {
            const careerCreated = await CareerPlanAPI.create(formValues)
            educationPlan.value = careerCreated as EducationPlan
          }
          toast.add({ severity: 'success', detail: msgSuccess.value, life: 3500 })
        } catch (e) {
          console.error(e)
        } finally {
          saving.value = false
          publishing.value = false
        }
      }
    }
    const publish = () => {
      publishing.value = true
      formValues.published = !formValues.published
      if (formValues.published) {
        msgSuccess.value = 'Datos Publicado'
      } else {
        msgSuccess.value = 'Datos Despublicado'
      }
      saveInfo()
    }
    const save = () => {
      saving.value = true
      msgSuccess.value = 'Datos guardados'
      saveInfo()
    }

    onMounted(() => educationPlan.value && patchValues(educationPlan.value as any))

    return {
      formValues,
      saving,
      publishing,
      publish,
      save,
      isCoursePlan
    }
  }
})
