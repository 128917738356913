
import { Difficulty } from '@/models/academic-definitions/enums'
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    return {
      types: Object.values(Difficulty).map((d) => ({ name: d, value: d }))
    }
  }
})
